<template lang="">
    <div class="formQuestionList">
        <Warning :isOpen="isWarning" :text="textWanrning" :onOK="handleClickOkWarning" />
        <div v-if="!isLoadingFormQuestion">
            <h2 class="formQuestionList__title">{{ formObject.title }}</h2>
            <div class="my-10 text-center" v-if="isReadOnly">
                <button class="btn" @click="handleClickBack">{{ $t('go_back') }}</button>
            </div>
            <div class="formQuestionList__item" v-for="(question, index) in formQuestionList">
                <div>
                    <h4>
                        {{ $t('test_page.question') }} {{ index + 1 }}
                        <span :style="{ color: 'red' }" v-if="question.mandatory">
                            ({{ $t('form_page.answer_required') }})
                        </span>
                        :
                    </h4>
                    <MyEditor :data="question.question_sentence" :isReadOnly="true" />
                </div>
                <div class="formQuestionList__answer" v-if="question.question_type === 'SINGLE_CHOICE_RADIO'">
                    <a-radio-group
                        @change="(e) => onChangeRadio(e, question)"
                        :defaultValue="isReadOnly ? handleDefaultValue(question) : ''"
                    >
                        <div v-for="answer in question.form_answers" :key="answer.id">
                            <a-radio :value="answer.id" :disabled="isReadOnly">
                                <MyEditor :data="answer.description" :isReadOnly="true" />
                            </a-radio>
                        </div>
                    </a-radio-group>
                </div>
                <div class="formQuestionList__answer" v-if="question.question_type === 'MULTIPLE_CHOICE'">
                    <a-checkbox-group
                        @change="(values) => onChangeCheckbox(values, question)"
                        :defaultValue="isReadOnly ? handleDefaultValue(question) : []"
                    >
                        <div v-for="answer in question.form_answers" :key="answer.id">
                            <a-checkbox :value="answer.id" :disabled="isReadOnly">
                                <MyEditor :data="answer.description" :isReadOnly="true" />
                            </a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
                <div class="formQuestionList__answer" v-if="question.question_type === 'TEXT'">
                    <a-textarea
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        @change="(e) => onChangeTextarea(e, question)"
                        :defaultValue="isReadOnly ? handleDefaultValue(question) : ''"
                        :disabled="isReadOnly"
                    />
                </div>
            </div>
            <NoData v-if="!formQuestionList.length" />
            <div class="my-10 text-center">
                <button class="btn" @click="handleClickBack">{{ $t('go_back') }}</button>
                <button class="btn btn-submit" @click="handleClickSubmit" v-if="!isReadOnly">
                    {{ $t('form.submit') }}
                </button>
            </div>
        </div>
        <Loading v-if="isLoadingFormQuestion" />
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';

import Warning from '../Warning';
import NoData from '../NoData';
import Loading from '../Loading/Loading.vue';
import MyEditor from '../../CMS/MyEditor';
import { mixinLoadings, mixinToasts } from '../../../mixins';

import { FormQuestion } from '../../../api';

export default {
    mixins: [mixinLoadings, mixinToasts],
    props: {
        isReadOnly: {
            type: Boolean,
            required: false,
        },
    },
    components: {
        Warning,
        NoData,
        Loading,
        MyEditor,
    },
    data() {
        return {
            userAnswers: [], // [ {question_id: string, answer_id: [string], text: string} ]
            isWarning: false,
            textWanrning: '',
        };
    },
    computed: {
        ...mapState('formQuestion', ['isLoadingFormQuestion', 'formObject', 'formRecordObject', 'formQuestionList']),
    },
    async created() {
        await this.getFormQuestions({
            form_id: this.$route.params.id,
        });

        if (this.isReadOnly) {
            await this.getOneFormRecord({
                record_id: this.$route.query.record_id,
                course_id: this.$route.query.course_id,
            });
        }
    },
    methods: {
        ...mapActions('formQuestion', ['getFormQuestions', 'getOneFormRecord']),

        onChangeRadio(e, question) {
            this.handleAddAnswer({
                question_id: question.id,
                question_type: question.question_type,
                answer_id: [e.target.value],
                text: '',
            });
        },

        onChangeCheckbox(values, question) {
            this.handleAddAnswer({
                question_id: question.id,
                question_type: question.question_type,
                answer_id: values,
                text: '',
            });
        },

        onChangeTextarea: _.debounce(function (e, question) {
            this.handleAddAnswer({
                question_id: question.id,
                question_type: question.question_type,
                answer_id: [],
                text: e.target.value.trim(),
            });
        }, 200),

        handleDefaultValue(question) {
            if (this.formRecordObject?.form_question_records) {
                const formQuestionRecord = this.formRecordObject?.form_question_records?.find(
                    (item) => item.form_question_id === question.id,
                );

                if (formQuestionRecord) {
                    if (question.question_type === 'SINGLE_CHOICE_RADIO') {
                        return formQuestionRecord?.form_choice_records?.[0]?.form_answer_id || '';
                    } else if (question.question_type === 'MULTIPLE_CHOICE') {
                        return formQuestionRecord?.form_choice_records?.map((item) => item?.form_answer_id) || [];
                    } else if (question.question_type === 'TEXT') {
                        return formQuestionRecord?.form_choice_records?.[0]?.answer_text || '';
                    }
                }

                return;
            }
        },

        handleAddAnswer(answerObject) {
            const newUserAnswers = [...this.userAnswers];
            const indexAnswer = newUserAnswers.findIndex((answer) => answer.question_id === answerObject.question_id);

            if (indexAnswer !== -1) {
                if (answerObject.question_type === 'MULTIPLE_CHOICE' && answerObject.answer_id.length === 0) {
                    newUserAnswers.splice(indexAnswer, 1);
                } else if (answerObject.question_type === 'TEXT' && answerObject.text === '') {
                    newUserAnswers.splice(indexAnswer, 1);
                } else {
                    newUserAnswers[indexAnswer].answer_id = answerObject.answer_id;
                }
            } else {
                newUserAnswers.push(answerObject);
            }

            this.userAnswers = newUserAnswers;
        },

        handleClickOkWarning() {
            this.isWarning = false;
        },

        handleClickBack() {
            this.$router.go(-1);
        },

        handleClickSubmit() {
            let isValid = true;
            const questionRequiredList = this.formQuestionList.filter((item) => item.mandatory);

            if (questionRequiredList.length > 0) {
                // Check answered all question required
                questionRequiredList.every((question) => {
                    const findIndexAnswer = this.userAnswers.findIndex((item) => item.question_id === question.id);
                    if (findIndexAnswer === -1) {
                        isValid = false;
                        this.textWanrning = this.$t('form_page.please_answer_all_question_required');
                        return false;
                    }
                    return true;
                });
            } else {
                if (this.userAnswers.length === 0) {
                    isValid = false;
                    this.textWanrning = this.$t('form_page.please_answer_one_question');
                }
            }

            if (!isValid) {
                this.isWarning = true;
                return;
            }

            this.$confirm({
                title: this.$t('form.submit'),
                content: this.$t('form_page.confirm_submit_form'),
                okText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                centered: true,
                onOk: () => {
                    this.handleAttemptForm();
                },
                onCancel: () => {},
            });
        },

        async handleAttemptForm() {
            this.showLoading();
            try {
                const body = {
                    course_id: this.$route.query.course_id,
                    form_id: this.$route.params.id,
                    answers: this.userAnswers,
                    time: 0,
                };
                await FormQuestion.attemptForm(body);
                this.handleClickBack();
            } catch (error) {
                this.showError(error);
            }
            this.hideLoading();
        },
    },
};
</script>

<style lang="scss">
.formQuestionList {
    & .formQuestionList__item {
        margin-top: 10px;
    }
    & .btn {
        height: 40px;
        width: 180px;
        padding: 6px 8px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        background: transparent;
        border: 2px solid #007aff;
        border-radius: 30px;
        color: #007aff;
        transition: all 0.3s ease;
    }
    & .btn:not(.btn-submit):hover {
        background-color: rgba(25, 118, 210, 0.04);
    }
    & .btn-submit {
        margin-left: 10px;
        background-color: #007aff;
        color: #fff;
    }
}

.formQuestionList__title {
    margin-bottom: 30px;
    font-size: 30px;
    text-align: center;
}

.formQuestionList__item {
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    & h4,
    & p {
        font-size: 16px;
    }
}

.formQuestionList__answer {
    margin-top: 28px;

    & .ant-radio-group,
    & .ant-checkbox-group,
    & .ant-checkbox-wrapper {
        width: 100%;
    }

    & .ant-radio-wrapper,
    & .ant-checkbox-wrapper {
        display: flex !important;
        height: auto !important;
        line-height: 1 !important;
        margin-left: 0;
        padding: 10px;
        font-size: 16px;
        transition: all 0.3s ease;
        white-space: unset;

        &:hover {
            background-color: #ececec;
            border-radius: 5px;
        }

        & i {
            display: none;
            margin-left: 5px;
            font-size: 18px;
            color: inherit;
        }
    }

    & .ant-radio,
    & .ant-checkbox {
        height: max-content;
        margin-top: 3px;
    }

    & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
        border-color: #1890ff !important;
    }

    & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #fff;
    }

    & .ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled span {
        color: #000;
    }

    & .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
        border-color: #1890ff !important;
    }

    & .ant-radio-wrapper-checked.ant-radio-wrapper-disabled span {
        color: #000;
    }

    & .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
        background-color: #1890ff;
    }

    & .ant-input.ant-input-disabled {
        color: #000;
    }
}

@media screen and (max-width: 767px) {
    .formQuestionList {
        & .btn {
            width: 140px;
        }
    }
}
</style>
