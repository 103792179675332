<template lang="">
    <div>
        <a-modal v-model="isOpen" :centered="true" :closable="false" :maskClosable="false" :footer="false">
            <div class="warning">
                <img src="../../../assets/images/warning.png" alt="warning.png" />
                <h3>{{ text }}</h3>
                <div class="flex align-center">
                    <button class="btn-ok" @click="onOK">OK</button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        isOpen: { type: Boolean },
        text: { type: String },
        onOK: { type: Function },
    },
};
</script>

<style lang="scss">
.warning {
    text-align: center;
    & img {
        width: 100px;
        margin-bottom: 20px;
    }
    & h3 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    & .btn-ok {
        height: 40px;
        width: 120px;
        padding: 6px 8px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        background: transparent;
        border: 2px solid #000;
        border-radius: 30px;
        color: #000;
        transition: all 0.3s ease;
        &:hover {
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
            transform: translateY(-2px);
        }
    }
}
</style>
