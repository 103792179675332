<template lang="">
    <div class="box-noData">
        <a-empty :description="$t('no_data')" />
    </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.box-noData {
    padding: 30px;
    background-color: #fff;
    border-radius: 16px;
}
</style>
