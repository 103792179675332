<template lang="">
    <div class="formView">
        <FormQuestionList :isReadOnly="true" />
    </div>
</template>

<script>
import FormQuestionList from '../../components/Web/FormQuestion/FormQuestionList.vue';

export default {
    components: {
        FormQuestionList,
    },
};
</script>

<style lang="scss">
.formView {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: 40px auto;
}
</style>
